<template>
  <!-- style="height: calc(100vh - 140px)" -->
  <v-sheet class="employee" id="employee">
    <v-row>
      <!-- {{ employeeList }} -->
      <v-flex md="12" class="d-flex py-2 mx-2" style="max-width: 100%; overflow: auto">
        <v-card
          class="w-20 mx-2"
          style="width: 100%; min-width: 300px; border: 0.5px solid #ffab91"
        >
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="red lighten-5" variant="tonal" class="">
                    <v-icon color="red" size="34" dark class="custom-icon-label"
                      >mdi-calendar</v-icon
                    >
                  </VAvatar>
                  MONTH
                  <v-chip v-if="selectedMonth" small color="#0D47A1" class="text-white">{{
                    selectedMonth ? selectedMonth : "No"
                  }}</v-chip>
                </v-list-item-title>
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading"
                  ><v-chip color="red" style="font-size: 18px; color: white; font-weight: 600"
                    >3</v-chip
                  ></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
        <v-card
          class="w-20 mx-2"
          style="width: 100%; min-width: 300px; border: 0.5px solid #90caf9"
        >
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label me-0"
                  style="font-weight: 600; font-size: 18px"
                >
                  <VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="">
                    <v-icon dark color="blue" size="34" class="custom-icon-label">person</v-icon>
                  </VAvatar>
                  KITCHEN</v-list-item-title
                >
              </v-list-item-content>
              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="blue" style="font-size: 18px; color: white; font-weight: 600"
                    >86.04%</v-chip
                  >
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
        <v-card
          class="w-20 mx-2"
          style="width: 100%; min-width: 300px; border: 0.5px solid #a5d6a7"
        >
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="green lighten-5" variant="tonal" class="">
                    <v-icon dark color="green" size="34" class="custom-icon-label"
                      >mdi-calendar</v-icon
                    >
                  </VAvatar>
                  ALCOHOLIC</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="green" style="font-size: 18px; color: white; font-weight: 600"
                    >42%</v-chip
                  >
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
        <v-card
          class="w-20 mx-2"
          style="width: 100%; min-width: 300px; border: 0.5px solid #ce93d8"
        >
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="pink lighten-5" variant="tonal" class="">
                    <v-icon dark size="34" color="pink" class="custom-icon-label"
                      >mdi-calendar</v-icon
                    >
                  </VAvatar>
                  NON-ALCOHOLIC</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="pink" style="font-size: 18px; color: white; font-weight: 600"
                    >23.00%</v-chip
                  ></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
        <v-card
          class="w-20 mx-2"
          style="width: 100%; min-width: 300px; border: 0.5px solid #81c784"
        >
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="success lighten-5" variant="tonal" class="">
                    <v-icon dark size="34" color="success" class="custom-icon-label"
                      >mdi-calendar</v-icon
                    >
                  </VAvatar>
                  TOTAL
                </v-list-item-title>
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading"
                  ><v-chip color="success" style="font-size: 18px; color: white; font-weight: 600"
                    >141.24%</v-chip
                  ></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-row>

    <!-- <v-row>
      <v-layout class="">
        <v-col md="3" class="">
          <v-card class="mx-auto" style="border: 0.5px solid #81c784">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="green lighten-5"
                      variant="tonal"
                      class="me-3"
                    >
                      <v-icon size="34" color="green darken-4" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    
                    MONTH
                    <v-chip v-if="selectedMonth" small color="#0D47A1" class="text-white">{{
                      selectedMonth ? selectedMonth : "No"
                    }}</v-chip>
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip color="#1B5E20" style="font-size: 18px; color: white; font-weight: 600"
                      >45</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card class="mx-auto" style="border: 0.5px solid #90caf9">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label me-0"
                    style="font-weight: 600; font-size: 18px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="blue lighten-5"
                      variant="tonal"
                      class="me-3"
                    >
                      <v-icon size="34" color="blue darken-4" dark>mdi-calendar</v-icon>
                    </VAvatar>
                   
                    TOTAL EMPLOYEE</v-list-item-title
                  >
                </v-list-item-content>
                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600"
                      >86.04%</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card class="mx-auto" style="border: 0.5px solid #4caf4e">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                  <VAvatar
                      :size="55"
                      rounded
                      color="green lighten-5"
                      variant="tonal"
                      class="me-3"
                    >
                      <v-icon size="34" color="green" dark>mdi-calendar</v-icon>
                    </VAvatar>
                   
                    ACTIVE EMPLOYEE</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="#4CAF4E" style="font-size: 18px; color: white; font-weight: 600"
                      >42%</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card class="mx-auto" style="border: 0.5px solid #e57373">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                  <VAvatar
                      :size="55"
                      rounded
                      color="red lighten-5"
                      variant="tonal"
                      class="me-3"
                    >
                      <v-icon size="34" color="red" dark>mdi-calendar</v-icon>
                    </VAvatar>
                   
                    INACTIVE EMPLOYEE</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="#E53935" style="font-size: 18px; color: white; font-weight: 600"
                      >23.00%</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-layout>
    </v-row>  -->
    <v-card-text class="px-0">
      <v-row>
        <v-col md="2" class="py-0">
          <label for="year" class="custom-form-label" style="font-weight: 600"
            >Filter By Year</label
          >
          <SelectInput
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="year_month_list.year_list"
            :itemText="`y_year`"
            placeholder="Select Year"
          >
          </SelectInput>
        </v-col>
        <v-col md="2" class="py-0">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter by Month</label
          >
          <SelectInput
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="year_month_list.month_list"
            v-model="selectedMonth"
            :itemText="`month`"
            placeholder="Select Month"
          >
          </SelectInput>
          <!-- itemValue="id" -->
        </v-col>
        <v-col class="mt-5">
          <v-row>
            <v-flex>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    tile
                    class="ml-2 white--text me-1"
                    >Filter</v-btn
                  >
                </template>
                <span>Filter</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    tile
                    class="ml-2 white--text me-1"
                    v-on:click="resetTableFiltered"
                    >Reset Filter</v-btn
                  >
                </template>
                <span>Reset Filter</span>
              </v-tooltip>
            </v-flex>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-row>
      <v-col md="12" class="my-auto py-0">
        <v-simple-table fixed-header class="bt-table table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="heading in employeeTableHeading"
                  :key="heading.title"
                  style="background-color: #f5f5f5"
                >
                  <div v-if="heading.status">{{ heading.title }}</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <template v-if="employeeList.length">
                <tr
                  v-for="(row, idx) in employeeList"
                  :key="`_$_index_$_${idx}`"
                  :class="idx % 2 === 0 && 'blue lighten-5'"
                  class="salary-listing-tr"
                >
                  <td v-for="(th, index) in employeeTableHeading" :key="'key_' + index">
                    <div
                      v-if="getColValue(th.title) === 'month' && th.status"
                      link
                      v-on:click="routeToDetail(row)"
                      class="cursor-pointer pa-0 p-0"
                    >
                      {{ row.name }}

                      <!-- <ValueTemplate
                        v-model="row[getColValue(th.title)]"
                        :title="row[getColValue(th.title)]"
                      ></ValueTemplate> -->
                    </div>

                    <div
                      v-else-if="getColValue(th.title) === 'kitchen' && th.status"
                      link
                      v-on:click="routeToDetail(row)"
                      class="cursor-pointer"
                    >
                      <ValueTemplate
                        v-model="row[getColValue(th.title)]"
                        :title="row[getColValue(th.title)]"
                      ></ValueTemplate>
                    </div>

                    <div
                      v-else-if="getColValue(th.title) === 'alcoholic' && th.status"
                      link
                      v-on:click="routeToDetail(row)"
                      class="cursor-pointer"
                    >
                      <ValueTemplate
                        v-model="row[getColValue(th.title)]"
                        :title="row[getColValue(th.title)]"
                      ></ValueTemplate>
                    </div>

                    <div
                      v-else-if="getColValue(th.title) === 'non_alcoholic' && th.status"
                      link
                      v-on:click="routeToDetail(row)"
                      class="cursor-pointer"
                    >
                      <ValueTemplate
                        v-model="row[getColValue(th.title)]"
                        :title="row[getColValue(th.title)]"
                      ></ValueTemplate>
                    </div>
                    <div
                      v-else-if="getColValue(th.title) === 'total' && th.status"
                      link
                      v-on:click="routeToDetail(row)"
                      class="cursor-pointer"
                      style="font-weight: 800"
                    >
                      <ValueTemplate
                        v-model="row[getColValue(th.title)]"
                        :title="row[getColValue(th.title)]"
                      ></ValueTemplate>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no customer at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 9" :key="`_$skeleton_$_locader_${idr}`">
                <td v-for="idk in 9" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <template>
          <v-layout class="light-border-top mt-4">
            <v-flex md6>
              <label class="btx-label p-4">{{ showingString }}</label>
            </v-flex>
            <v-flex md6>
              <v-pagination
                color="blue darken-4"
                v-model="currentPage"
                :length="totalPages"
                :total-visible="7"
                v-on:input="getSegments()"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </template>
      </v-col>
    </v-row>

    <CreateSegment
      v-if="generateSegmentDialog"
      :dialog="generateSegmentDialog"
      @close="generateSegmentDialog = false"
      @success="getSegments()"
    />
    <Dialog :dialog="dialog" dialogFullscreen>
      <template v-slot:title>
        <v-flex class="d-flex justify-content-between">
          <p class="my-auto py-0">SALARY</p>
          <v-btn
            class="my-auto py-0"
            depressed
            tile
            :disabled="pageLoading"
            v-on:click="dialog = false"
          >
            Close
          </v-btn></v-flex
        >
      </template>
      <template v-slot:body>
        <SalaryList></SalaryList>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import CreateSegment from "@/view/module/marketing/CreateSegment";
import SalaryList from "@/view/components/SalaryList";
import ValueTemplate from "@/view/components/ValueTemplate";
import SelectInput from "@/view/components/SelectInput";
import { GET_DEPARTMENTPRODUCT } from "@/core/lib/marketing.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CHANGE_SEGMENTS_STATUS, DELETE_SEGMENTS } from "@/core/lib/marketing.lib";
import Dialog from "@/view/components/Dialog";
export default {
  name: "employee-create",
  title: "Create Customer",
  components: { ValueTemplate, SelectInput, CreateSegment, Dialog, SalaryList },
  data() {
    return {
      pageLoading: false,
      dialog: false,
      generateSegmentDialog: false,
      deleteDialog: false,
      deleteText: null,
      deleteId: null,
      showingString: null,
      currentPage: 1,
      loadPagination: false,
      totalPages: null,
      deleteEndpoint: null,
      selectedMonth: null,
      year_month_list: {
        month_list: [
          {
            id: 0,
            month: "January (1 - 15)",
          },
          {
            id: 1,
            month: "January (16 - 30)",
          },
          {
            id: 2,
            month: "February (1 - 15)",
          },
          {
            id: 3,
            month: "February (16 - 30)",
          },
          {
            id: 4,
            month: "March (16 - 30)",
          },
          {
            id: 5,
            month: "March (1 - 15)",
          },
        ],

        year_list: [
          {
            id: 0,
            y_year: "2020",
          },
          {
            id: 1,
            y_year: "2021",
          },
          {
            id: 2,
            y_year: "2022",
          },
          {
            id: 3,
            y_year: "2023",
          },
        ],
      },
      salaryEmployeeList: [
        {
          //   action: { value: "", key: "ACTION" },
          id: 0,
          month: { id: 0, value: "January (1 - 15)", key: "MONTH" },
          kitchen: { value: "10.00%", key: "KITCHEN" },
          alcoholic: { value: "12.00%", key: "ALCOHOLIC" },
          non_alcoholic: { value: "", key: "NON ALCOHOLIC" },
          total: { value: "22%", key: "TOTAL" },
        },
        {
          id: 1,
          month: { id: 1, value: "January (16 - 30)", key: "MONTH" },
          kitchen: { value: "14.06%", key: "KITCHEN" },
          alcoholic: { value: "23.00%", key: "ALCOHOLIC" },
          non_alcoholic: { value: "20.00%", key: "NON ALCOHOLIC" },
          total: { value: "57.06%", key: "TOTAL" },
        },
        // {
        //   id: 2,
        //   month: { id: 2, value: "February (1 - 15)", key: "MONTH" },
        //   kitchen: { value: "26.09%", key: "KITCHEN" },
        //   alcoholic: { value: "01.00%", key: "ALCOHOLIC" },
        //   non_alcoholic: { value: "03.00", key: "NON ALCOHOLIC" },
        //   total: { value: "30.09%", key: "TOTAL" },
        // },
        // {
        //   id: 3,
        //   month: { id: 3, value: "February (16 - 30)", key: "MONTH" },
        //   kitchen: { value: "28.09%", key: "KITCHEN" },
        //   alcoholic: { value: "04.00%", key: "ALCOHOLIC" },
        //   non_alcoholic: { value: "", key: "NON ALCOHOLIC" },
        //   total: { value: "32.09%", key: "TOTAL" },
        // },
        // {
        //   id: 4,
        //   month: { id: 4, value: "March (16 - 30)", key: "MONTH" },
        //   kitchen: { value: "06.09%", key: "KITCHEN" },
        //   alcoholic: { value: "02.00%", key: "ALCOHOLIC" },
        //   non_alcoholic: { value: "", key: "NON ALCOHOLIC" },
        //   total: { value: "08.09%", key: "TOTAL" },
        // },
      ],
      employeeList: [],
      dragStartIndex: null,
      dragOverIndex: null,
      employeeTableHeading: [
        { title: "MONTH", status: true },
        { title: "KITCHEN", status: true },
        { title: "ALCOHOLIC", status: true },
        { title: "NON ALCOHOLIC", status: true },
        { title: "TOTAL", status: true },
      ],
    };
  },
  computed: {
    filteredSalaryEmployeeList() {
      if (!this.selectedMonth) {
        return this.salaryEmployeeList;
      }
      const filtered_the_data = this.salaryEmployeeList.filter(
        (row) => row.month.value === this.selectedMonth
      );
      // const filtered_the_data = this.salaryEmployeeList.filter(
      //   (item) => item.month.id == this.selectedMonth
      // );
      return filtered_the_data;
    },
  },

  methods: {
    getSalary() {
      this.dialog = true;
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").replaceAll("(", "").replaceAll(")", "").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.employeeTableHeading[this.dragStartIndex];
      this.employeeTableHeading.splice(this.dragStartIndex, 1);
      this.employeeTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    resetTableFiltered() {
      this.selectedMonth = null;
    },
    goToSegmentDetail(id) {
      this.$router.push({
        name: "broadcast-detail",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    goBrodcastupdate(id) {
      this.$router.push({
        name: "update-broadcast",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(row) {
      this.$router.push({
        name: "consumptionReport-detail",
        params: { id: row.id, row: row },
        query: { t: new Date().getTime() },
      });
    },
    async getSegments() {
      const _this = this;
      this.pageLoading = true;
      try {
        const { rows, showing_string, totalPages } = await GET_DEPARTMENTPRODUCT(
          this.currentPage
          //this.per_page
        );
        this.employeeList = rows;
        this.showingString = showing_string;
        this.totalPages = totalPages;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
    async segmentsDeleteSuccess() {
      await this.getSegments();
      this.deleteDialog = false;
    },
    async deleteSegment() {
      const _this = this;
      const params = {
        selected: [_this.deleteId],
      };
      this.pageLoading = true;
      try {
        await DELETE_SEGMENTS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment delete successfully." },
        ]);
        await this.getSegments();
        this.deleteDialog = false;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async deleteConfirm(name, id) {
      this.deleteText = name;
      this.deleteId = id;
      this.deleteDialog = true;
    },
    async changeSegmentStatus(status, id) {
      const _this = this;
      const params = {
        action: status == 1 ? "active" : "inactive",
        selected: [id],
      };
      this.pageLoading = true;
      try {
        await CHANGE_SEGMENTS_STATUS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment Status Change Successfully." },
        ]);
        await this.getSegments();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    await this.getSegments();
  },
};
</script>
<style>
.table_height .v-data-table__wrapper {
  height: calc(100vh - 360px);
}
.salary-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 99;
}

.salary-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
